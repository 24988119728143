import React from "react";
// import hitflora from "../assets/svg/hitflora-white-red.svg";

function CarouselBanner() {
  return (
    <div>
      <div className="flex flex-row gap-10 bg-gradient-to-r from-violetGradient to bg-pinkGradient w-full h-24">
        <div className="flex flex-row gap-24 items-center justify-center px-44 mx-auto">
          {" "}
        </div>
      </div>
    </div>
  );
}

export default CarouselBanner;
